
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































































































































































































































































// Copied styles from FormSelect
.form-select {
  $radius: 0.4rem;

  position: relative;
  z-index: 1;
  width: 100%;

  .label {
    @extend %ff-alt;

    margin-bottom: $spacing * 0.5;
    color: $c-dark-grey;
  }

  .select {
    position: relative;
    flex: 1;
  }

  .trigger {
    @extend %fw-bold;

    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing * 0.5 $spacing;
    color: currentColor;
    background: $c-white;
    border: 1px solid $c-light-grey;
    border-radius: $radius;

    .icon {
      margin-left: $spacing;
      transition: transform 0.2s;
    }

    &:focus-visible {
      outline: 3px solid $c-dark-grey;
    }
  }

  .list {
    @include scrollbars(6px, $c-light-grey, $c-white);

    position: absolute;
    z-index: 1;
    bottom: 4px; // Hide border
    overflow-y: auto;
    width: 100%;
    height: auto;
    max-height: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
    background: $c-white;
    border-radius: 0 0 $radius $radius;
    box-shadow: 0 4px 50px rgba(177, 177, 177, 0.4);
    transition: 0.3s;

    &.is-expanded {
      max-height: 300px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: $radius;
    }
  }

  .option {
    display: block;
    width: 100%;
    padding: $spacing * 0.25 $spacing;
    line-height: 30px;
    transition: 0.1s background, opacity 0s;

    &.placeholder {
      color: rgba($c-gray-dark, 0.5);
    }

    &:hover,
    &:focus {
      background: $c-gray-light;
    }
  }
}
