
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































































































































































.projects-enter ::v-deep {
  .project-archive__heading,
  .project-archive__grid {
    opacity: 0;
  }
}

.ctas {
  @include default-margin(0.5);
}
