
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































































































































































































































































































.project-archive-content {
  position: relative;
}

.project-archive__heading {
  @include mq(m) {
    display: flex;
  }
}

.title {
  @include mq(m) {
    flex: 1;
    flex-basis: col(5, 10);
    padding-right: 5%;
  }

  @include mq(xl) {
    padding-right: 10%;
  }
}

.intro {
  @include mq(m) {
    flex: 1;
    flex-basis: col(5, 10);
  }
}

.project-archive__highlighted {
  @include mq(s) {
    ::v-deep .magazine-card__content {
      margin-left: $spacing * 2;
      font-size: 2.5rem;
    }
  }
}

.project-archive__grid {
  transition: opacity 0.2s;

  &.before-load {
    opacity: 0;
  }
}

.project-archive__grid__loadmore {
  text-align: center;
}
