
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
$dd-c-white: rgb(255, 255, 255);
$dd-c-gray-lighter: rgb(235, 235, 235);
$dd-c-gray-light: rgb(215, 215, 215);
$dd-c-default: $dd-c-white;
$dd-c-border: $dd-c-gray-light;
$dd-c-current: $dd-c-gray-lighter;
$dd-c-selected: $dd-c-gray-light;

.default-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $spacing * 0.55 $spacing * 0.75;
  background: $c-white;
  border: 0;
  border-radius: 5px;

  svg {
    margin-left: auto;
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(180deg);
    }
  }
}

.default-option {
  padding: 0.33em 1em;
  background-color: $dd-c-default;

  &.is-selected,
  &.is-selected:not(:hover) {
    background-color: $dd-c-current;
  }

  &.is-active,
  &[aria-selected='true'],
  &:hover {
    background-color: $dd-c-selected;
  }
}

.dropdown {
  position: relative;
}

.label,
.select {
  display: block; // Some declaration to avoid undefined selector
}

.trigger,
.option {
  cursor: pointer;
}

.list {
  position: absolute;
  z-index: 1;
  bottom: 1px;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: $dd-c-default;
  border: 1px solid $dd-c-border;
}

.option {
  &.is-active,
  &.is-selected {
    list-style-type: none; // Some declaration to avoid undefined selector
  }
}
