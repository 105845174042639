
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































.project-categories {
  position: sticky;
  z-index: 2;
  top: var(--header-height-down);
  padding: $spacing 0 $spacing;
  background: $c-white;
  border-top: 1px solid $c-gray-lighter;
  transition: 1s $ease-80-20;

  ::v-deep {
    .trigger {
      transition: border-color 1s $ease-80-20;
    }
  }

  &.is-sticky {
    border-color: $c-white;
    box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
    transition: 1s $ease-80-20, padding 0.5s 0.1s $ease-out-expo;
  }

  @include mq(l) {
    top: calc(var(--header-height-down) - #{$spacing * 2});
    padding: $spacing * 2 0 0;

    &.is-sticky {
      ::v-deep {
        /* stylelint-disable-next-line max-nesting-depth */
        .trigger {
          border-color: $c-gray-lighter;
          border-top-color: $c-white;
          border-bottom-color: $c-white;
          border-radius: 0;
        }
      }
    }
  }
}

.project-categories__inner {
  @include mq(l) {
    display: flex;
    justify-content: space-between;
    gap: $spacing * 2;
  }

  @include mq(xl) {
    gap: $spacing * 4;
  }
}

.form-select {
  z-index: 2;

  & + & {
    z-index: 1;
    margin-top: $spacing;
  }

  @include mq(l) {
    display: flex;
    align-items: baseline;
    width: col(4.75, 10);
    white-space: nowrap;

    & + & {
      margin-top: 0;
    }

    ::v-deep {
      .select {
        flex: 1;
        margin-left: $spacing * 0.5;
        transition: width 0.5s;
      }

      .is-sticky & .list {
        bottom: 2px;
      }
    }
  }

  @include mq(xl) {
    width: col(4.5, 10);

    ::v-deep {
      .select {
        margin-left: $spacing * 1.5;
      }
    }
  }
}
